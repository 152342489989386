import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import EcoSystemBenefitsCard from "../../../../V2/Cards/EcoSystem/Benefits"

import { getUrlFromStoryblokLink, linkIsNotNull } from "@utils/storyblok"

const StoryblokEcoSystemBenefitsCard = ({
  blok,
}: Storyblok.BlokProps<Storyblok.EcoSystemBenefitsCard>) => {
  return (
    <EcoSystemBenefitsCard
      backgroundColor={blok.backgroundColor}
      superscriptText={blok.superscriptText}
      titleText={blok.titleText}
      descriptionText={blok.descriptionText}
      imageUrl={blok.image.filename}
      imageAlt={blok.image.alt}
      buttonText={blok.ctaText}
      imageRounded={blok.imageRounded}
      buttonLink={
        blok.ctaLink && linkIsNotNull(blok.ctaLink)
          ? getUrlFromStoryblokLink(blok.ctaLink)
          : undefined
      }
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    />
  )
}

export default StoryblokEcoSystemBenefitsCard
